<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="searchform" style="flex:1" :inline="true" :rules="searchrules" :model="search" class="demo-form-inline">
        <el-form-item prop="search_type">
          <el-select placeholder="搜索方式" @change="ChangeType" v-model="search.search_type">
            <el-option label="根据UUID搜索" :value="1"></el-option>
            <el-option label="根据出厂凭证搜索" :value="2"></el-option>
            <el-option label="根据年份搜索" :value="3"></el-option>
            <el-option label="查询旧平台表" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="search.search_type==1" prop="uuid">
          <el-input v-model.trim="search.uuid" placeholder="设备UUID"></el-input>
        </el-form-item>
        <el-form-item v-if="search.search_type==2" prop="token">
          <el-input v-model.trim="search.token" placeholder="出厂凭证"></el-input>
        </el-form-item>
        <el-form-item v-if="search.search_type==3">
          <el-date-picker v-model="search.year" type="year" placeholder="年份"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="search.search_type==3">
          <el-select clearable v-model="search.factory_code" placeholder="工厂">
            <el-option v-for="item in factorylist" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="search.search_type==3">
          <el-select clearable v-model="search.product_code" placeholder="产品">
            <el-option v-for="item in prolist" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="search.search_type==3">
          <el-select clearable v-model="search.product_model_code" placeholder="产品型号">
            <el-option v-for="item in promodellist" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="search.search_type==2||search.search_type==3||search.search_type==4">
          <el-select clearable placeholder="设备出厂状态" v-model="search.status">
            <el-option label="待激活" :value="1"></el-option>
            <el-option label="已激活" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="search.search_type==2||search.search_type==3||search.search_type==4">
          <el-input v-model.trim="search.sn" placeholder="设备SN"></el-input>
        </el-form-item>
        <el-form-item v-if="search.search_type==2||search.search_type==3||search.search_type==4">
          <el-input v-model.trim="search.mac" placeholder="设备MAC"></el-input>
        </el-form-item>
        <el-form-item v-if="search.search_type==2||search.search_type==3||search.search_type==4">
          <el-date-picker
            v-model="datevalue"
            type="daterange"
            range-separator="To"
            start-placeholder="出厂开始时间"
            end-placeholder="出厂结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" @click="Validate(Search)">查询</el-button>
      </el-form>
    </div>

    <el-table border v-bind:data="list">
      <el-table-column label="产品名称">
        <template v-slot="scope">
          <span>{{ scope.row.product_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品型号">
        <template v-slot="scope">
          <span>{{ scope.row.product_model_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="生产工厂">
        <template v-slot="scope">
          <span>{{ scope.row.factory_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="UUID">
        <template v-slot="scope">
          <span>{{ scope.row.uuid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="SN">
        <template v-slot="scope">
          <span>{{ scope.row.sn }}</span>
        </template>
      </el-table-column>
      <el-table-column label="MAC">
        <template v-slot="scope">
          <span>{{ scope.row.mac }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="scope">
          <span>{{ scope.row.status==1?'待激活':scope.row.status==2?'已激活':'未知' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="出厂凭证">
        <template v-slot="scope">
          <el-button type="text" size="mini" @click="Look(scope.row.apply_token)">查看出厂凭证</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.rows"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog title="出厂凭证" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" ref="form">
        <el-form-item label="token" label-width="100px">
          <el-input disabled v-model="form.token" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Copy">复制</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../axios/productionManage'
import util from '../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      datevalue: '',
      search: {
        search_type: '',
        uuid: '',
        token: '',
        page: 1,
        rows: 10,
        status: null,
        mac: '',
        sn: '',
        start_time: '',
        end_time: '',
        year: '',
        product_code: '',
        product_model_code: '',
        factory_code: '',
      },
      searchrules: {
        search_type: [{ required: true, message: '请选择查询方式', trigger: 'blur' }],
        uuid: [{ required: true, message: '请输入UUID', trigger: 'blur' }],
        token: [{ required: true, message: '请输入出厂凭证', trigger: 'blur' }],
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      form: {
        token: ''
      },
      factorylist: [],
      promodellist: [],
      prolist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    ChangeType () {
      this.list = []
      this.search.uuid =  ''
      this.search.token =  ''
      this.search.page =  1
      this.search.rows =  10
      this.search.status =  null
      this.search.mac =  ''
      this.search.sn =  ''
      this.search.start_time =  ''
      this.search.end_time =  ''
      this.search.year =  ''
      this.search.product_code =  ''
      this.search.product_model_code =  ''
      this.search.factory_code =  ''
      this.total = 0
    },
    GetFactory () {
      api.FactoryList({
        data: {
          page: 1,
          rows: 100,
        },
        payload_version: 'v1.0',
        region: 'gb'
      }).then(res => {
        if (res.data.code == 200) {
          this.factorylist = res.data.data.list
          this.GetProModel()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetProModel() {
      this.api.ProductModelList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.promodellist = res.data.data.data
          this.GetPro()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetPro () {
      this.api.ProductList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.prolist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Validate: function (call) {
      this.$refs['searchform'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Search() {
      this.list = []
      let requestType
      switch (this.search.search_type) {
        case 1:
          requestType = api.UuidDeviceList
          break;
        case 3:
          requestType = api.YearDeviceList
          break;
        case 2:
          requestType = api.TokenDeviceList
          break;
        case 4:
          requestType = api.OldDeviceList
          break;
      
        default:
          break;
      }
      requestType({
        region: 'gb',
        data: {
          page: this.search.page,
          rows: this.search.rows,
          uuid: this.search.uuid,
          token: this.search.token,
          status: this.search.status?this.search.status:0,
          mac: this.search.mac,
          sn: this.search.sn,
          start_time: this.datevalue?Date.parse(this.datevalue[0])/1000:null,
          end_time: this.datevalue?Date.parse(this.datevalue[1])/1000:null,
          year: this.search.search_type==3?this.search.year.getFullYear()+'':"",
          product_code: this.search.product_code,
          product_model_code: this.search.product_model_code,
          factory_code: this.search.factory_code,
        },
        payload_version: 'v1.0'
      }).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.list
          this.total = res.data.data.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Look (item) {
      this.dialogFormVisible = true
      this.form.token = item
    },
    Copy() {
      this.dialogFormVisible = false
      var inputTest = document.createElement('input');
      inputTest.value = this.form.token;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = 'oInput';
      inputTest.style.display = 'none';
      this.$message.success('复制成功')
    },
    ChangeTime (item) {
      return util.dateFormat(item)
    }
  },
  filters: {},
  mounted () {
    this.search.year = new Date()
  },
  created () {
    this.GetFactory()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
